import { Close, Info, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  NativeSelect,
  Typography,
  styled,
} from "@mui/material";
import { format, set } from "date-fns";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import docotorImg from "../../assets/doctor.jpg";
import { setStep } from "../../reducers/appointmentInfoSlice";
import { setDoctor, setSession } from "../../reducers/placeAppointmentSlice";
import palette from "../../theme/palette";
import { useEffect } from "react";
import getOriginalDateTime from "../../utils/formatAMPM";
import CustomModal from "../CustomModal/CustomModal";
import { convertToCurrency } from "../../utils/convert-to-currency";
import DoctorDetailsPopUp from "./DoctorDetailsPopUp";
import { SESSION_CATEGORIES, SESSION_CATEGORIES_REVERSED } from "../../const";
import { getDoctorSessionCategoryV2 } from "../../app/apiManager/doctorServices";

const SelectDate = styled("div")(
  `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  width: 50px;
  border: 1px solid #ccc;
  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgba(61, 61, 61, 0.3);
  }
`,
  (props) => ({
    backgroundColor: props.selected ? palette.light.primary.main : null,
    color: props.selected ? "white" : "black",
  })
);

const ViewMoreButtonContainer = styled("div")(
  `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 50px;
  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgba(61, 61, 61, 0.3);
  }
`
);

const SelectTime = styled("div")(
  `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 1px rgba(61, 61, 61, 0.3);
  }
`,
  (props) => ({
    backgroundColor: props.selected ? palette.light.primary.main : null,
    color: props.selected ? "white" : "black",
  })
);

const ErrorText = styled(Typography)({
  color: "red",
  fontSize: "0.8rem",
  paddingLeft: "5px",
});

const DoctorCard = ({ doctor, selectFirstAvailableSession }) => {
  const dispatch = useDispatch();
  const [viewMore, setViewMore] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [dateNotSelected, setDateNotSelected] = useState(false);
  const [timeNotSelected, setTimeNotSelected] = useState(false);
  const doctorImageLink = `${process.env.REACT_APP_VC_DOMAIN}${process.env.REACT_APP_IMAGE_PATH}${doctor.doctorId}.png`;
  const [sessionCategory, setSessionCategory] = useState("NO_SELECTION");
  const [isLoading, setIsLoading] = useState(false);
  const [doctorSessionCategory, setDoctorSessionCategory] = useState([]);

  const onClickChannel = async () => {
    getDoctorSessionCategory();
    setViewMore((prev) => !prev);
  };

  const getDoctorSessionCategory = async () => {
    try {
      setIsLoading(true);
      const response = await getDoctorSessionCategoryV2({
        InstituteBranchId: 31029,
        DoctorId: doctor.doctorId,
      });


      setDoctorSessionCategory(response);
      if (Array.isArray(response) && response.length === 1) {
        setSessionCategory(response[0]?.SessionCategoryId);
      }

    } catch (err) {
      console.log(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectDate = useCallback(
    (date) => {
      setSelectedDate(date);
      if (selectFirstAvailableSession) {
        setSelectedSession("");
      }
    },
    [setSelectedDate, selectFirstAvailableSession]
  );

  const onSelectTime = useCallback(
    (session) => {
      setSelectedSession(session);
    },
    [setSelectedSession]
  );

  const sessionsAvailable = useMemo(
    () => doctor?.sessions.length > 0,
    [doctor]
  );

  const sessionAvailability = useCallback(
    (doctor, fontSize) => {
      if (sessionsAvailable) {
        return (
          <Typography
            sx={{
              ml: 2,
              fontSize: fontSize ? fontSize : "0.7rem",
              color: "green",
            }}
          >
            Available on {doctor?.sessions[0]?.timeStart.split("T")[0]}
          </Typography>
        );
      } else {
        return (
          <Typography
            sx={{
              ml: 2,
              fontSize: fontSize ? fontSize : "0.7rem",
              color: "red",
            }}
          >
            No sessions available
          </Typography>
        );
      }
    },
    [sessionsAvailable]
  );

  const onClickBookAppointment = useCallback(() => {
    if (selectedDate === "") {
      setDateNotSelected(true);
      return;
    } else {
      setDateNotSelected(false);
    }
    if (selectedSession === "" && selectedDate !== "") {
      setTimeNotSelected(true);
      return;
    } else {
      setTimeNotSelected(false);
    }
    dispatch(setDoctor(doctor));
    dispatch(setSession(selectedSession));
    dispatch(setStep({ step: 1 }));
  }, [dispatch, doctor, selectedSession, selectedDate]);

  const sessionDates = {};
  doctor?.sessions.forEach((session) => {
    if (session.sessionCategoryId === sessionCategory) {
      const date = session.timeStart.split("T")[0];
      if (!sessionDates[date]) {
        sessionDates[date] = [session];
      } else {
        sessionDates[date].push(session);
      }
    }
  });

  const DateNotSelected = dateNotSelected ? (
    <ErrorText>* select a date to continue</ErrorText>
  ) : (
    <></>
  );

  const TimeNotSelected = timeNotSelected ? (
    <ErrorText>* select a time to continue</ErrorText>
  ) : (
    <></>
  );

  useEffect(() => {
    if (sessionsAvailable && selectFirstAvailableSession) {
      const selectedDate = Object.keys(sessionDates)[0];
      setSelectedDate(selectedDate);
      setSelectedSession(
        sessionDates[selectedDate] ? sessionDates[selectedDate][0] : ""
      );
    }
  }, [selectFirstAvailableSession]);

  useEffect(() => {
    const specializations = doctor?.specialization?.split(",");
    if (Array.isArray(specializations) && specializations.length >= 1) {
      setSessionCategory(SESSION_CATEGORIES_REVERSED[specializations[0]]);
    }
  }, [doctor]);

  return (
    <Box width="100%" p={0.5}>
      <DoctorDetailsPopUp
        doctor={doctor}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
        docotorImg={docotorImg}
        doctorImageLink={doctorImageLink}
        sessionAvailability={sessionAvailability}
      />
      <Box
        p={1}
        sx={{
          borderRadius: 1.5,
          boxShadow: "0px 0px 5px 1px rgba(61, 61, 61, 0.15)",
          "&:hover": {
            boxShadow: "0px 0px 8px 1px rgba(61, 61, 61, 0.30)",
            cursor: "pointer",
          },
        }}
      >
        <Box display={"flex"} justifyContent="start" alignItems={"start"}>
          <Box
            display={"flex"}
            flexGrow={1}
            onClick={() => {
              setShowDetails(true);
            }}
          >
            <img
              src={doctorImageLink}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = docotorImg;
              }}
              style={{ borderRadius: "50%", width: "60px", height: "60px" }}
            />

            <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
              <Typography
                sx={{
                  mt: 1,
                  ml: 2,
                  fontWeight: "bold",
                  flexGrow: 1,
                  fontSize: "13px",
                }}
              >
                {doctor.title + " " + doctor.firstName + " " + doctor.lastName}
              </Typography>
              <Typography
                sx={{
                  ml: 2,
                  fontSize: "0.7rem",
                  wordWrap: "break-word",
                  maxWidth: "150px",
                }}
              >
                {doctor.specialization}
              </Typography>
              {sessionAvailability(doctor)}
            </Box>
          </Box>
          {viewMore ? (
            <IconButton title="Hide details" onClick={onClickChannel}>
              <KeyboardArrowUp />
            </IconButton>
          ) : (
            <Button
              variant="text"
              sx={{
                alignSelf: "center",
                mt: 1,
                borderRadius: 5,
                boxShadow: "none",
                fontSize: "0.75rem",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
              size="small"
              disabled={!sessionsAvailable}
              onClick={onClickChannel}
            >
              Book now
            </Button>
          )}
        </Box>
        {viewMore && (
          <Box>
            <Box>
              <Typography sx={{ fontSize: "0.8rem", mt: 1 }}>
                Session category
              </Typography>

              <FormControl
                fullWidth
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "none",
                  },
                  my: 0.5,
                  border: `1px solid transparent`,
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      my: 1,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <NativeSelect
                    variant="outlined"
                    sx={{
                      "& select": {
                        backgroundColor: "transparent",
                        px: 2,
                      },
                      "& :focus": {
                        backgroundColor: "transparent",
                      },
                      "& :before": {
                        borderBottom: "none",
                      },
                      py: "4px",
                      border: `1px solid ${palette.light.grey[400]}`,
                      borderRadius: "5px",
                    }}
                    value={sessionCategory}
                    onChange={(e) => {
                      setSessionCategory(e.target.value);
                    }}
                  >
                    <option value={"NO_SELECTION"} disabled>
                      Please select
                    </option>

                    {doctorSessionCategory.map((category, index) => (
                      <option value={category.SessionCategoryId}>
                        {category.Name}
                      </option>
                    ))}
                  </NativeSelect>
                )}
              </FormControl>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              mt={1}
            >
              <Typography>Select date</Typography> {DateNotSelected}
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              my={1}
              gap={1}
            >
              {sessionCategory === "NO_SELECTION" ? (
                <Typography
                  component={"div"}
                  flexGrow={1}
                  sx={{ fontSize: "0.8rem", color: "red" }}
                >
                  Please select a category
                </Typography>
              ) : (
                Object.keys(sessionDates).map((date) => (
                  <SelectDate
                    key={date}
                    onClick={() => onSelectDate(date)}
                    selected={selectedDate === date}
                  >
                    <p>{format(new Date(date), "MMM")}</p>
                    <p>{format(new Date(date), "d")}</p>
                  </SelectDate>
                ))
              )}

              {Object.keys(sessionDates).length === 0 &&
                sessionCategory !== "NO_SELECTION" && (
                  <Typography
                    component={"div"}
                    flexGrow={1}
                    sx={{ fontSize: "0.8rem", color: "red" }}
                  >
                    No sessions available for{" "}
                    {SESSION_CATEGORIES[sessionCategory]}
                  </Typography>
                )}
            </Box>

            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              mt={1}
            >
              <Typography>Select time</Typography>
              {TimeNotSelected}
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              flexWrap={"wrap"}
              mt={1}
              gap={1}
            >
              {sessionCategory !== "NO_SELECTION" &&
                sessionDates[selectedDate]?.map((session) => {
                  const { appointmentLimit, currentAppointments } = session;

                  if (!appointmentLimit) {
                    return (
                      <SelectTime
                        key={session.timeStart}
                        onClick={() => onSelectTime(session)}
                        selected={selectedSession === session}
                      >
                        <p>
                          {format(
                            new Date(getOriginalDateTime(session.timeStart)),
                            "p"
                          )}{" "}
                          -{" "}
                          {format(
                            new Date(getOriginalDateTime(session.timeEnd)),
                            "p"
                          )}
                        </p>
                      </SelectTime>
                    );
                  } else if (appointmentLimit > currentAppointments) {
                    return (
                      <SelectTime
                        key={session.timeStart}
                        onClick={() => onSelectTime(session)}
                        selected={selectedSession === session}
                      >
                        <p>
                          {format(
                            new Date(getOriginalDateTime(session.timeStart)),
                            "p"
                          )}{" "}
                          -{" "}
                          {format(
                            new Date(getOriginalDateTime(session.timeEnd)),
                            "p"
                          )}
                        </p>
                      </SelectTime>
                    );
                  }
                })}
              {sessionDates[selectedDate] ||
              sessionCategory === "NO_SELECTION" ||
              Object.keys(sessionDates).length === 0 ? (
                <></>
              ) : (
                <Typography
                  component={"div"}
                  flexGrow={1}
                  sx={{ fontSize: "0.8rem", color: "red" }}
                >
                  Select a date to view available times
                </Typography>
              )}
            </Box>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={onClickBookAppointment}
            >
              Book Appointment
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DoctorCard;
