import { Close } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPatientsList } from "../../app/apiManager/patientServices";
import logo from "../../assets/logo.png";
import medicaLogo from "../../assets/medica.png";
import CustomModal from "../../components/CustomModal/CustomModal";
import TableHeading from "../../components/PatientsTable/TableHeading";
import TableRow from "../../components/PatientsTable/TableRow";
import { signIn } from "../../reducers/signInSlice";
import { setStep } from "../../reducers/appointmentInfoSlice";

const SelectPatient = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const nextRoute = location?.state?.nextRoute;
  const phoneNo = useSelector((state) => state.appointmentInfo.phoneNo);

  const [selectedId, setSelectedId] = useState("");
  const dispatch = useDispatch();
  const [patientList, setPatientList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attempts, setAttepmts] = useState(0);

  useEffect(() => {
    if (attempts < 3) {
      let phoneNumber = phoneNo;
      // remove +94, 94, from phone number
      if (phoneNumber.length === 9) {
        phoneNumber = "0" + phoneNumber;
      } else if (phoneNumber.startsWith("+94")) {
        phoneNumber = `0${phoneNumber.replace("+94", "")}`;
      } else if (phoneNumber.startsWith("94")) {
        phoneNumber = `0${phoneNumber.replace("94", "")}`;
      }

      getPatientsList(
        phoneNumber,
        (data) => {
          if (data) {
            setPatientList(data);
            setAttepmts(3);
          } else {
            setAttepmts(attempts + 1);
          }
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, [attempts]);

  return (
    <CustomModal
      open={open}
      sx={{
        p: 2,
        pl: 2.5,
        py: 1.5,
        width: { xs: "90%", sm: 450 },
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontweight: "bold",
          }}
        >
          Select Patient
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(false);
            navigate("/");
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <div>
        <Box
          sx={{
            width: "100%",
            height: {
              xs: "20rem",
              md: "16rem",
            },
            mt: 1,
            position: "relative",
          }}
        >
          <TableHeading />

          {loading ? (
            <LinearProgress />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: {
                  xs: "18rem",
                  md: "14rem",
                },
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            >
              <>
                {patientList.map((patient, index) => {
                  return (
                    <TableRow
                      name={patient.FirstName + " " + patient.LastName}
                      dob={patient.DateOfBirth}
                      patientId={patient.Id}
                      email={patient.Email}
                      gender={patient.Gender}
                      key={index}
                      selectedId={selectedId}
                      onClick={() => {
                        dispatch(signIn({ ...patient }));
                        dispatch(setStep({ step: 0 }));
                        if (nextRoute) {
                          navigate(nextRoute);
                          return;
                        }
                        navigate("/");
                      }}
                    />
                  );
                })}
              </>
              <Box pt={2}></Box>
            </Box>
          )}
        </Box>
      </div>

      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        mt={2}
        gap={0.5}
      >
        <img src={logo} alt="docnote logo" width={"90px"} />
        <Divider
          orientation="vertical"
          sx={{
            mt: 0.5,
            width: "2px",
            height: "25px",
            backgroundColor: "#ABABAB",
          }}
        />
        <img src={medicaLogo} alt="medica logo" width={"120px"} style={{}} />
      </Box>
    </CustomModal>
  );
};

export default SelectPatient;
